// Color system
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800
  ),
  $colors
);

$emma:          $accent-dark;
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "emma":       $emma,
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
  ),
  $theme-colors
);

// Options
$enable-responsive-font-sizes: true;

// Body
$body-color: rgba($black, .5);

// Links
$link-color: theme-color("emma");

// Typography
$headings-font-family: $title-font;
$font-weight-base:     200;

// Components
$border-radius:     0;
$border-radius-lg:  0;
$border-radius-sm:  0;

// Navbar
$navbar-light-hover-color:        $accent-dark;
$navbar-light-active-color:       $accent-dark;
$navbar-brand-font-size:          2rem;
$navbar-light-brand-color:        rgba($black, .75);
$navbar-light-brand-hover-color:  $accent-dark;

// Dropdowns
$dropdown-link-color:             rgba($black, .5);
$dropdown-link-hover-color:       rgba($black, .75);
$dropdown-link-hover-bg:          rgba($accent-dark, .75);
$dropdown-link-active-color:      $white;
$dropdown-link-active-bg:         $accent-dark;
$dropdown-border-radius:          0;
$dropdown-border-width:           0;

// Forms
$input-placeholder-color:         rgba($gray-600, .75);
