.hero {
  width: 100%;
  height: 38vw;
  overflow: hidden;

  &.hero-small {
    height: 18vw;
    min-height: 120px;
  }

  img {
    position: fixed;
    top: 56px;
    left: 0;
    z-index: -1;

    @include media-breakpoint-up(sm) {
      top: calc(1rem + (1.325rem + .9vw) * 1.5);
    }

    @include media-breakpoint-up(xl) {
      top: 4rem;
    }
  }

  .h4 {
    font-family: $font-family-sans-serif;
    font-weight: 200;
    text-transform: uppercase;
  }

  .lead {
    position: relative;
    font-style: italic;
    font-size: 1.5rem;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 30px;
      height: 1px;
      background-color: $white;
      top: 50%;
    }

    &::before {
      left: -40px;
    }

    &::after {
      right: -40px;
    }
  }
}