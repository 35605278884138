#faq {
  .card-header {
    background-color: rgba($accent-dark, 0.125);

    &:hover {
      background-color: rgba($accent-dark, 0.5);
    }

    h3 {
      .btn {
        font-size: 1.25rem;
        color: rgba($black, .75);

        i {
          color: $accent-dark;
          margin-right: .5rem;
          transition: transform .125s linear;
        }

        &:hover {
          text-decoration: none;
        }

        &[aria-expanded="true"] {
          i {
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  .collapse,
  .collapsing {
    font-weight: 400;
  }
}