#header {
  box-shadow: 0 0 2px rgba($black, .25);

  .navbar-brand {
    font-family: $headings-font-family;
  }

  @include media-breakpoint-up(lg) {
    .navbar-nav {
      .nav-item>.nav-link {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          width: 0;
          height: 1px;
          background-color: $accent-dark;
          top: 0;
          left: 50%;
          right: 50%;
          transition-property: width, left, right;
          transition-duration: 0.25s;
          transition-timing-function: ease-out;
        }

        &.active  {
          &::before {
            background-color: $accent-dark;
            width: 100%;
            left: 0;
            right: 0;
          }
        }

        &:hover {
          &::before {
            width: 100%;
            left: 0;
            right: 0;
          }
        }
      }
    }
  }

  .dropdown-menu {
    top: calc(100% + 15px);
    box-shadow: 0 0 2px rgba($black, .25);

    .dropdown-item {
      font-weight: 200;
    }
  }
}