body {
  width: 100vw;
  overflow-x: hidden;
  padding-top: 56px;

  @include media-breakpoint-up(sm) {
    padding-top: calc(1rem + (1.325rem + .9vw) * 1.5);

  }

  @include media-breakpoint-up(xl) {
    padding-top: 4rem;
  }
}

main {
  background-color: $light;
}

.container-fluid,
.container {
  padding-bottom: 1px;
}

strong {
  color: rgba($black, .625);
}

.browser-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba($white, 0.8);
  z-index: 9000;
}

.preprod-badge {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1000;
}

button:focus {
  outline: none;
}

.btn {
  font-weight: 200;
}

.accent-heading {
  color: rgba($black, .75);
  margin-bottom: 1.5rem;

  small {
    color: rgba($gray-600, .75);
    font-family: $font-family-base;
    font-size: 60%;
    font-weight: 200;
  }

  &::after {
    content: '';
    display: block;
    width: 60px;
    height: 1px;
    background-color: $accent-dark;
    margin-top: 1rem;
  }

  &.centered {
    text-align: center;

    &::after {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.bg-accent {
  background-color: rgba($emma, .125);
  padding-bottom: 1px;

  .table-list {
    tr td {
      border-top-color: $accent-dark;
    }
  }
}

.tiles {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.carousel-indicators {
  li {
    background-color: rgba($black, .2);
  }
}

.blockquote-title {
  font-weight: 600;
  font-style: italic;
  margin-bottom: 0;
  color: rgba($black, .125);
}

.blockquote-footer {
  font-size: 1.25rem;
  font-weight: 300;
  font-style: italic;
  color: $emma;
}

.custom-select {
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */

  &:invalid {
    color: rgba($gray-600, .75);
  }
}

.price-card {
  .card-header,
  .card-footer {
    font-family: $font-family-base;
    font-weight: 200;
    text-transform: uppercase;
  }
  .card-footer {
    border-top: none;
  }
}

.table-list {
  tr:first-child td {
    border-top: none;
  }
}

.img-hoverable {
  position: relative;
  overflow: hidden;

  .hover-layer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;

    .inner-bg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      right: 50%;
      background-color: rgba($white, .5);
      transform: rotate(-45deg) scale(1.5);
      transition: left ease-out .25s, right ease-out .25s;
    }

    .inner {
      width: 100%;
      height: 100%;
      color: rgba($black, .5);
      transform: scale(1.5);
      opacity: 0;
      transition: transform ease-out .25s, opacity ease-out .25s;
    }
  }

  &:hover {
    .hover-layer {
      .inner-bg {
        left: 0;
        right: 0;
      }

      .inner {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
}

.reset-btn:hover {
  text-decoration: none;
}

.form-alert {
  display: none;
}

.accented {
  font-size: 1.5rem;
}

.text-block {
  h3 {
    color: $dark;
  }
}

.conseils {
  ul {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    background: $white;

    li {
      position: relative;
      display: block;
      padding: .75rem 1.25rem;
      background: rgba($accent-light, .25);
      color: $dark;
      border: 1px solid rgba(0,0,0,0.125);
      font-size: 1rem;

      &:nth-child(even) {
        background: rgba($accent-dark, .25);
      }

      & + li {
        border-top-width: 0;
      }
    }
  }
}