footer {
  h2 {
    color: rgba($black, .75);
  }

  a {
    color: $body-color;

    &:hover,
    &:focus {
      color: inherit;
    }
  }

  .social {
    a:hover {
      color: rgba($black, .75);
      text-decoration: none;
    }
  }

  .nav-item {
    .nav-link {
      padding: 0 .75rem;
      font-weight: 300;

      &:hover,
      &.active {
        color: rgba($black, .75);
      }
    }

    &:last-child {
      .nav-link {
        @include media-breakpoint-up(lg) {
          padding-right: 0;
        }
      }
    }
  }
}