@charset "utf-8";

/* -- Google Fonts -- */
@import url('https://fonts.googleapis.com/css?family=Italiana&display=swap');

/* -- Variables -- */
$title-font: 'Italiana', serif;
$accent-light: #F6B8B8;
$accent-dark: #ac8181;

/* -- font-awesome path override -- */
$fa-font-path: "/fonts";

/* -- Imports -- */
@import "modules/bootstrap";
@import "../../node_modules/ekko-lightbox/dist/ekko-lightbox";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "partials/global";
@import "partials/header";
@import "partials/footer";
@import "partials/hero";
@import "partials/faq";